@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }

  body {
    @apply antialiased;
  }

  /* Dark mode styles */
  .dark {
    @apply bg-gray-900 text-white;
  }
}

@layer components {
  .btn {
    @apply px-4 py-2 rounded-md transition-colors;
  }

  .btn-primary {
    @apply bg-blue-500 text-white hover:bg-blue-600;
  }

  .input {
    @apply w-full p-2 rounded-md border focus:ring-2 focus:ring-blue-500 focus:border-transparent;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 0.5s ease-out;
}
